.imageDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  height: unset;
}

.topImage {
  width: 100vw;
}

.titleWrapper {
  width: 100vw;
  display: flexbox;
}

.titleBox {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: unset;
  border: unset;
  width: 100vw;
  padding: 0;
}

.mobileFlex {
  display: flex;
  margin: 0 2vw;
  height: unset !important;
  border-bottom: unset;
}

.bookButton {
  width: 96vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bookButton:focus {
  outline: none !important;
}

.bookButton:hover {
  background-color: #279bab;
  cursor: pointer;
}

.noItemDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 50vh;
}

@media only screen and (min-width: 1200px) {
  .imageDiv {
    height: 80vh;
  }

  .titleWrapper {
    display: flex;
  }

  .mobileFlex {
    margin: 0;
    min-height: 8vh;
    width: 25vw;
    justify-content: center;
    align-items: center;
    border-bottom: 8px solid #279bab;
  }

  .titleBox {
    min-height: 8vh;
    border-bottom: 8px solid #279bab;
    width: 50vw;
    padding-left: 25vw;
  }

  .bookButton {
    width: 20vw;
    height: 50% !important;
  }
}