.imageDiv {
    margin-top: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    height: unset;
  }
  
  .banner {
    max-height: unset;
    min-width: unset;
    height: 70vh;
  }
  
  .textDiv {
    width: unset;
    margin: 2vh 4vw;
  }
  
  @media only screen and (min-width: 1300px) {  
    .imageDiv {
      height: 75vh;
    }
    
    .banner {
      min-width: 100vw;
      height: unset;
    }
  
    .textDiv {
      width: 70vw;
      margin: 2vh 15vw;
      font-size: 1.25rem;
    }
  }
