.gridWrapper {
  padding: 0 2vw;
}

.itemMid {
  border-bottom: 2px solid #279bab;
  border-top: 2px solid #279bab;
  padding: 0;
}

.itemRight {
  padding: 2vh 1vw 0 1vw;
}

@media only screen and (min-width: 1200px) {
  .gridWrapper {
    margin: 2vh 2vw;
  }

  .itemMid {
    border-right: 3px solid #279bab;
    border-left: 3px solid #279bab;
    border-bottom: unset;
    border-top: unset;
    padding: 0 1vw;
  }

  .itemLeft {
    padding: 0 1vw;
  }

  .itemRight {
    padding: 0 3vw 0 1vw;
  }
}