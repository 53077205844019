.imageDiv {
  margin-top: -5%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  position: relative;
}

.topImage {
  max-height: unset;
  min-width: unset;
  height: 95vh;
}

.marketingImageDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
}

.marketingImage {
  max-height: unset;
  min-width: unset;
  height: 50vh;
}

.welcomeTxt {
  position: absolute;
  bottom: 10% !important;
  margin: 0 10vw;
  font-weight: bold;
}

.svgDiv {
  width: 100%;
  position: absolute;
  justify-content: center;
  display: flex;
}

.marketingSvg {
  border: 6px solid white;
  border-radius: 50px;
  margin: 0 4%;
  padding: 20px;
}

.videoGrid {
  margin-top: 0 !important;
}

.videoItem {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2vh !important;
}

.video {
  width: 100%;
}

@media only screen and (min-width: 1200px) {
  .topImage {
    min-width: 100vw;
    height: unset;
  }

  .welcomeTxt {
    bottom: 15% !important;
    left: 17% !important;
    margin: 0;
    max-width: 30%;
  }

  .marketingImage {
    min-width: 100vw;
    height: unset;
  }

  .svgDiv {
    width: 80%;
  }

  .marketingSvg {
    width: 16%;
    margin: 0 10%;
  }

  .videoGrid {
    margin-top: 2vh !important;
  }
  
  .videoItem {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0 !important;
  }

  .video {
    width: 95%;
  }
}