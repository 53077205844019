.productCarousel {
  position: relative;
  overflow: hidden;
  padding: 4vh 0;
}

.productContainer {
  margin: 0 10vw;
  display: flex;
  overflow-x: hidden;
  scroll-behavior: smooth;
  text-align: center;
  overflow-y: hidden;
}

.preBtn {
  position: absolute;
  top: 45%;
  font-size: 3em !important;
  color: #279bab;
  cursor: pointer;
}

.nextBtn {
  position: absolute;
  top: 45%;
  right: 0;
  font-size: 3em !important;
  color: #279bab;
  cursor: pointer;
}

.card {
  width: 85vw;
}

.card:hover {
  cursor: pointer;
  background-color: #f5f1e6;
}

.productImage {
  width: 80vw;
}

.priceDiv {
  background-color: #279bab;
  max-width: 24vw;
  padding: 2px;
  color: #fff;
  margin: -12vh 0 3vh 2vw;
  border-radius: 4px;
  position: relative;
  z-index: 1000;
}

@media only screen and (min-width: 1200px) {
  .productCarousel {
    padding: 4vh 0 10vh 0;
  }

  .productContainer {
    margin: 0 15vw;
  }
  
  .preBtn {
    left: 3vw;
  }

  .nextBtn {
    right: 3vw;
  }

  .card {
    width: 40vw;
  }

  .productImage {
    width: 34.5vw;
  }
  .priceDiv {
    max-width: 8vw;
    margin: -12vh 0 3vh 2vw;
  }
}