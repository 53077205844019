.textDiv {
  width: unset;
  margin: 10vh 4vw 2vh 4vw;
}

.mapWrapper {
  width: 100%;
  display: flexbox;
  margin-top: 5vh;
}

.leftMap {
  width: 100%;
  float: left;
}

.rightMap {
  width: 100%;
  float: left;
  margin-top: 4vh;
}

@media only screen and (min-width: 1200px) {
  .textDiv {
    width: 70vw;
    margin: 25vh 15vw 2vh 15vw;
    font-size: 1.25rem;
  }

  .mapWrapper {
    width: 100%;
    display: flexbox;
    margin-top: 5vh;
  }

  .leftMap {
    width: 50%;
    float: left;
  }

  .rightMap {
    width: 50%;
    float: right;
    margin-top: 0;
  }
}